var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"text-black q-pa-sm no-shadow list-cards"},[_c('q-item',[_c('q-item-section',{attrs:{"top":"","avatar":""}},[_c('Avatar',{attrs:{"size":50,"id":_vm.data.id,"isOwner":false}})],1),_c('q-item-section',[_c('q-item-label',{staticClass:"text-weight-bold"},[_vm._v(_vm._s(_vm.data.first_name)+" "+_vm._s(_vm.data.last_name))]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v(_vm._s(_vm.data.nickname))]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v("Plays at "+_vm._s(_vm.data.course_name))])],1),_c('q-item-section',{attrs:{"side":"","top":""}},[_c('q-btn',{attrs:{"round":"","color":"primary","icon":"keyboard_arrow_right"},on:{"click":function($event){return _vm.$router.push(
                    {
                        name: 'Profile',
                        params: {
                            id: _vm.data.id
                        }
                    }
                )}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }