//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from '@/components/Avatar'
export default {
    props: ['data'],
    components: {
        Avatar
    }
}
